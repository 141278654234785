.supDivMain {
  display: flex;
  flex-direction: row;
  column-gap: 1vw;
  padding: 0px;
  margin: 0px;
  flex-wrap: nowrap;
  background-color: #fff;
  width: 100%;
  align-items: center;
  justify-content: left;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.iconMain {
  width: 50px;
  height: auto;
  object-fit: contain;
  padding: 10px 0px 10px 10px;
  cursor: pointer;
}

.backButton {
  display: none;
}

.salmTitleMain {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: auto;
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  cursor: pointer;
}

.superiorRoute {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: auto;
  padding: 0px;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 700px) {
  .supDivMain,
  .superiorRoute {
    display: none;
  }

  .supDivMain {
    display: grid;
    padding: 0px;
    margin: 0px;
    grid-template-columns: 10% 20% 55%;
    flex-wrap: wrap;
    background-color: #fff;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    top: 0px;
  }

  .iconMain {
    width: auto;
    height: 50px;
    padding: 5px 0px 5px 10px;
  }

  .salmTitleMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 20px;
  }

  .backButton {
    display: flex;
    width: 20px;
  }
}
