.carousel{
    display: flex;
    height: fit-content;
    align-items: center;
    justify-content: center;
  }

  .thumbnailContainer {
    display: flex;
    column-gap: 5px;
    overflow-x: scroll;
    padding: 10px;
  }
  
  .thumbnailImage {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    object-fit: cover;
    background-color: #ecebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  
  .thumbnailImage img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .thumbnailImage.selected {
    border-color: blue;
  }
  
  .fullscreenCarouselContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .fullscreenCarousel {
    display: flex;
    align-items: center;
  }
  
  .carouselImageContainer {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fullscreenImage {
    max-height: 95vh;
    max-width: 95vw;
    object-fit: contain;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: white;
    font-size: 24px;
  }
  