body {
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
}

* {
  font-family: "Nunito", sans-serif;
}
