.appBar {
  display: grid;
  grid-template-columns: max-content max-content auto 30px;
  width: 100%;
  margin: 0px;
  align-items: center;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
}

.appBrand {
  display: grid;
  grid-template-columns: 65px auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 65px;
  padding: 0px 30px;
}

.appIcon {
  padding: 5px;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.appName {
  width: 100%;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 650;
  color: #16b316;
}

.townSelector {
  display: grid;
  grid-template-columns: max-content max-content;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 40px 0px 20px;
}

.lineText {
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  font-size: 15px;
  font-weight: 650;
}

.selector {
  display: inline-flex;
  width: 100%;
  height: 44px;
  font-size: 15px;
  border-radius: 2px;
  text-align: center;
  border: none;
  padding: 0px 5px;
  background-color: transparent;
  color: black;
}

.selector:focus {
  outline: none;
}

.searchDiv {
  width: 100%;
  height: 44px;
  display: grid;
  grid-template-columns: max-content auto;
  flex-wrap: wrap;
  border-radius: 16px;
  border: 0px;
  background-color: #f3f1ff;
  align-items: center;
  padding: 0px 20px 0px 15px;
}

.searchInput {
  font-size: 15px;
  border: 0px;
  background-color: transparent;
  width: 100%;
  outline: none;
  margin-left: 10px;
}

@media (max-width: 750px) {
  .appBar {
    grid-template-columns: max-content max-content auto 10px;
  }

  .appBrand {
    padding: 0px 10px;
  }

  .townSelector {
    padding: 0px 10px;
  }

  .lineText {
    display: none;
  }
}

@media (max-width: 630px) {
  .appBrand {
    grid-template-columns: 55px auto;
  }

  .appName {
    display: none;
  }

  .appBrand {
    padding: 0px 0px 0px 10px;
  }

  .townSelector {
    padding: 0px 10px 0px 0px;
  }
}

@media (max-width: 420px) {
  .appBar {
    display: flex;
    flex-direction: column;
  }

  .appBrand {
    display: flex;
    height: auto;
    padding: 0px;
  }

  .appName {
    display: flex;
    width: 100%;
    font-size: 18px;
    justify-content: center;
    margin: 10px 0px 0px 0px;
  }

  .appIcon {
    display: none;
  }

  .searchDiv {
    width: 90%;
    margin-bottom: 10px;
    height: 40px;
  }

  .selector {
    font-size: 16px;
    padding: 0px 10px;
    margin: 0px 0px 10px 0px;
    height: 40px;
    border-bottom: 1px solid #16b316;
  }
}
