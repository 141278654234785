.mainBannerView {
  display: grid;
  grid-template-columns: 40% 60%;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.103);
}
.bannerView {
  padding: 5px 20px 0px 20px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 90%;
  height: 330px;
  background-position: center;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.verticalBannerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.horizontalBannerDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.iconBannerView {
  width: 180px;
  height: auto;
}

.bannerView img {
  max-width: 100%;
  max-height: 320px;
  margin: 0 auto;
  object-fit: contain;
}

.carousel .thumb,
.thumbs {
  display: none;
}

.carousel .thumbs-wrapper {
  margin: 0px;
}

.animatedBannerText {
  font-size: 26px;
  font-weight: 500;
  border-right: 2px solid #333;
  white-space: nowrap;
  overflow: hidden;
  width: max-content;
  height: max-content;
}

.staticText {
  margin: 10px;
  font-size: 26px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .mainBannerView {
    display: flex;
  }
  .bannerView {
    padding: 5px 20px 0px 20px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 260px;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.103);
  }
  .verticalBannerDiv {
    display: none;
  }
  .bannerView img {
    max-width: 100%;
    max-height: 250px;
    margin: 0 auto;
    object-fit: contain;
  }
}
