.infDivMain{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: white;
    column-gap: 25px;
    row-gap: 15px;
    padding-bottom: 20px;
    padding-top: 20px;
    box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.103);
}

.iconFooter{
    width: 50px;
}

.salmTitleFooter{
    font-size: 17px;
}

.buttonsFooter{
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    font-size: 17px;

}

.socialIcon{
    width: 30px;
    height: 30px;
}

@media (max-width: 660px) {
    .infDivMain{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        column-gap: 18px;
        row-gap: 7px;
        padding: 15px 8px 15px 8px;
        
        box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.103);
        margin-top: 5px;
    }

    .socialIcon{
        width: 20px;
        height: 20px;
    }

    .salmTitleFooter{
        font-size: 17px;
        margin: 5px 0px 5px 0px;
    }
}
