* {
  box-sizing: border-box;
}

:root {
  --primary-color: #16b316;
  --shadow-color: rgba(82, 82, 82, 0.5);
}

.contentItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 15px;
  column-gap: 15px;
  margin-bottom: 25px;
}

.itemView {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 270px;
  height: fit-content;
  padding: 20px;
  margin: 10px;
  border-radius: 25px;
  transition: margin 0.3s ease;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.15);
}

.itemView:hover {
  margin: 0px 10px;
}

.itemView:hover .statusLabel {
  position: absolute;
  top: 10px;
  left: 0px;
  background-color: #16b316;
  color: white;
  padding: 5px 7px 7px 7px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0px 10px 10px 0px;
  z-index: 1;
  transition: all 0.3s ease;
}

.itemImage {
  width: 100%;
  height: 135px;
  object-fit: cover;
  border-radius: 10px;
}

.statusLabel {
  position: absolute;
  top: 20px;
  left: 10px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 5px 7px 7px 7px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 0px 10px 10px 0px;
  z-index: 1;
  transition: all 0.3s ease;
}

.itemInfDiv {
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
}

.itemData {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}

.itemInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  width: 80%;
  height: auto;
}

.itemTitle {
  width: 100%;
  text-align: left;
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 5px;
  margin: 0;
  max-lines: 1;
  font-weight: bolder;
}

.itemText {
  padding: 5px;
  font-size: 15px;
  text-align: left;
  font-style: italic;
}

.itemTown {
  font-size: 15px;
  text-align: left;
  color: rgb(104, 104, 104);
  margin: 0;
  font-weight: 300;
}

.itemCalification {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  width: 60px;
  height: fit-content;
  border-radius: 8px;
  background-color: #ffeed4bb;
}

.calificationText {
  font-size: 15px;
  text-align: right;
  color: black;
  font-weight: 500;
  height: auto;
  font-weight: bold;
}

.calificationImage {
  padding: 7px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

@media (max-width: 900px) {
  .itemView {
    width: 40%;
  }
}

@media (max-width: 555px) {
  .contentItem {
    width: 100%;
    row-gap: 10px;
    margin: 0px 5px 5px 5px;
  }

  .itemView {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0px;
    border-radius: 0px;
    border-bottom: solid 1px #16b316;
  }

  .itemView:hover .statusLabel {
    position: absolute;
    top: 20px;
    left: 10px;
    background-color: #16b316;
    color: white;
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    z-index: 1;
  }

  .itemImage {
    width: 35%;
    height: auto;
    margin: 7px;
    border-radius: 5px;
  }

  .statusLabel {
    position: absolute;
    top: 20px;
    left: 10px;
    background-color: white;
    color: black;
    font-weight: 700;
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    z-index: 1;
  }

  .itemInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .itemTitle {
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding: 0;
    margin: 10px 10px 5px 10px;
  }

  .itemTown {
    position: relative;
    margin: 10px;
    padding: 5px;
    font-size: 15px;
    text-align: center;
    color: black;
    font-weight: 400;
    width: 100%;
    border-radius: 10px;
  }
}
