.categoriesContainer {
  display: flex;
  height: auto;
  overflow-x: auto;
  align-items: center;
  padding: 10px 0px 10px 0px;
}

.categoriesButton {
  padding: 10px;
  border-radius: 25px;
  border: none;
  background-image: linear-gradient(
    100deg,
    #53d153 -5.85%,
    #35cb35 62.91%,
    #1ad91a 109.55%
  );
  color: white;
  font-size: 15px;
  margin-right: 10px;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.categoriesButton:hover {
  background-image: none;
  color: #16b316;
  font-weight: bolder;
}

.subcategoriesButton {
  font-weight: bolder;
  padding: 10px;
  border-radius: 25px;
  border: 1px solid #16b316;
  color: #16b316;
  font-size: 15px;
  margin-right: 10px;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.subcategoriesButton:hover {
  background-image: linear-gradient(
    100deg,
    #53d153 -5.85%,
    #35cb35 62.91%,
    #1ad91a 109.55%
  );
  color: white;
  font-weight: 400;
}
