.userRegisterView {
  width: 99vw;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.supDivUserRegister {
  display: grid;
  grid-template-columns: 15% 85%;
  flex-wrap: wrap;
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100vw;
  height: auto;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 0px 10px 0px;
}

form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 150px;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  width: 70vw;
}

label {
  font-size: 20px;
}

.checkboxGroup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 8px;
  justify-content: start;
}

.checkboxGroup label {
  display: flex;
  align-items: center;
}

.checkboxGroup input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
