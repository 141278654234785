.homeView {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.mainHomeView {
  width: 100%;
  overflow: auto;
  overflow-y: scroll;
  row-gap: 0px;
}

.itemsTitle {
  width: 100%;
  font-size: 27px;
  font-weight: 620;
  text-align: center;
  padding: 0px;
  margin: 10px 0px 20px 0px;
  text-decoration: underline;
  text-decoration-color: #16b316;
}

.itemsView {
  margin: 0px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.categoriesView {
  display: flex;
  flex-direction: row;
  margin: 5px;
  justify-content: center;
}

@media (max-width: 767px) {
  .itemsView {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .itemsTitle {
    font-size: 20px;
  }
}
