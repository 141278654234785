.loginMainView {
  display: grid;
  grid-template-columns: 20% 80%;
  flex-wrap: wrap;
}

/* .loginView{
    width: 100vw;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.supDivLogin{
    display: grid;
    grid-template-columns: 15% 85%;
    flex-wrap: wrap;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    height: 10vh;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.iconLogin{
    width: 50%;
    padding-left: 20px;
    padding-right: 50px;
}

.mainViewLogin{
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 40% 60%;
    flex-wrap: wrap;
    margin-top: 150px;
    background-color: white;
}

.authMethodsView{
    width: 90%;
    align-items: center;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    background-color: aqua;
}

.photoLogin{
    width: 100%;
    height: auto;
}

.loginForm{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    padding-top: 15px;
    background-color: white;

}

.googleLoginButton{
    font-size: 20px;
    padding: 5px;
    border-radius: 20px;
    margin: 10px;
    text-align: center;
    border: 0px;
    background-color: #f7f8f9;
    font-weight: bold;
} */
