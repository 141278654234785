.publisherDetailsMainView {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 4% 96%;
  padding: 0px;
  margin: 0px;
}

.headerDetails {
  width: 100%;
  height: 100%;
}

.detailsView {
  display: grid;
  grid-template-columns: 400px auto;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.leftDetailsView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.coverImage {
  padding: 20px 20px 15px 20px;
  width: 100%;
}

.textHeaderDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.publisherName {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: 650;
  padding: 0px 20px 15px 20px;
  margin: 0px;
}

.publisherTown {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: white;
  padding: 10px;
  margin: 0px;
  background-color: #16b316;
}

.publisherCategory {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: white;
  padding: 10px;
  margin: 0px;
  background-color: #16b316d2;
}

.publisherSubcategory {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: white;
  padding: 10px;
  margin: 0px;
  background-color: #16b3169d;
}
.publisherAddress {
  width: 100%;
  text-align: center;
  padding: 0px;
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  padding: 20px 15px 20px 15px;
  color: black;
}

.publisherCalification {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background-color: #ffeed4be;
}

.calificationPublisherText {
  width: fit-content;
  text-align: left;
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  padding: 15px 15px 10px 15px;
  color: black;
  font-weight: bold;
}

.calificationPublisherImage {
  padding: 7px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.rightDetailsView {
  width: 100%;
  height: 100%;
  display: column;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 20px 30px 0px 30px;
  row-gap: 20px;
}

.cardsDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cardsDivDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f5fdf5;
}

.cardTitleDetails {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 0px;
  margin: 0px 10px 0px 10px;
  white-space: pre-line;
}

.cardTextDetails {
  width: 100%;
  text-align: left;
  font-size: 20px;
  white-space: pre-line;
  padding: 0px;
  margin: 0px 10px 10px 10px;
  margin: 0px;
  font-weight: 400;
  color: black;
}

.buttonsView {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  column-gap: 20px;
  row-gap: 20px;
}

.carouselMainDiv {
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
}

.carouselDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-position: center;
  justify-content: center;
}

.carouselDiv img {
  max-width: 100%;
  max-height: 500px; /* Ajusta el tamaño de las imágenes para que no excedan el ancho del carrusel */ /* Set the maximum height of the images to fill the carousel container */
  margin: 0 auto;
  object-fit: contain; /* Centra las imágenes horizontalmente */
}

.prevIcon {
  width: 35px;
}

@media (max-width: 660px) {
  .publisherDetailsMainView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
  }

  .detailsView {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }

  .cardsDivDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 10px;
    background-color: #f5fdf5;
    row-gap: 20px;
  }

  .buttonsView {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    row-gap: 20px;
  }
}

@media (max-width: 1100px) {
  .publisherDetailsMainView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
  }

  .detailsView {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }

  .cardsDivDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 0px 20px;
    background-color: #f5fdf5;
    border-radius: 30px;
    row-gap: 20px;
  }

  .buttonsView {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    row-gap: 20px;
  }
}
